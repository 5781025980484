import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Metadata } from '../components';
import StandardLayout from '../layouts/standard';
import homepageIcon from "../images/homepage-icon.png"

const IndexPage = () => {

	const { markdownRemark } = useStaticQuery(
		graphql`
			query {
        		markdownRemark(fileAbsolutePath: {regex: "/content\/home/"}) {
					frontmatter {
			  			title
						imageOverlayText
					}
					html
					htmlAst
		  		}
      		}
    	`
	);

	return (
		<StandardLayout>
			<Metadata title={ markdownRemark.frontmatter.title } />
			<img alt="Matt Dehnel Music icon in black and white, centered on the homepage" src={ homepageIcon } />
		</StandardLayout>
	)

};

export default IndexPage;
